.avatar {
    font-weight: 400;
    background: @gray-lighter;
//    .img-circle;
}
.avatar > img {
    .img-circle;
    max-width: 100%;
}

.avatar--sm .avatar {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px;
    font-size: 14px;
}

.avatar--xs .avatar {
    width: 25px !important;
    height: 25px !important;
    line-height: 26px;
    font-size: 12px;
}

.avatar--xxs .avatar {
    width: 12px !important;
    height: 12px !important;
    text-indent: -9999em;
    overflow: hidden;
}

.avatar--has-success {
    position: relative;
}
.avatar--has-success:before,
.avatar--has-success:after {
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: 16px;
    speak: none;
    text-rendering: auto;
    position: absolute;
    bottom: -4px;
    right: -4px;

    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.avatar--has-success:before {
    content: "\f269";
    color: #fff;
    font-size: 19px;
    bottom: -5px;
    right: -5px;
}
.avatar--has-success:after {
    content: "\f269";
    color: @brand-success;
}
.avatar--xxs .avatar--has-success:before,
.avatar--xxs .avatar--has-success:after {
    bottom: 2px;
    right: -1px;
}

.avatar--has-warning {
    position: relative;
}
.avatar--has-warning:before,
.avatar--has-warning:after {
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: 16px;
    speak: none;
    text-rendering: auto;
    position: absolute;
    bottom: -4px;
    right: -4px;

    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.avatar--has-warning:before {
    content: "\f26d";
    color: #fff;
    font-size: 19px;
    bottom: -5px;
    right: -5px;
}
.avatar--has-warning:after {
    content: "\f1f1";
    color: @brand-danger;
}
.avatar--xxs .avatar--has-warning:before,
.avatar--xxs .avatar--has-warning:after {
    bottom: 2px;
    right: -1px;
}
.icon-avatar {
    background: @gray-light;
    color: #fff;
    border-radius: 50%;
    line-height: 52px;
    height: 40px;
    width: 40px;
    position: relative;
    .text-center;
    i {
        font-size: 30px;
    }
    &.doubleline {
        line-height: @font-size-small;
        padding-top: 8px;
        font-size: @font-size-small;
        -webkit-font-smoothing: subpixel-antialiased;
    }
    .modal-title & {
        background: lighten(@gray, 40%);
        float: left;
        margin-right: @table-cell-padding * 2;
    }
}
.icon-avatar.icon-avatar--sm {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    display: inline-block;
}
.icon-avatar.icon-avatar--sm i {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    font-size: 22px;
    line-height: 34px;
    display: inline-block;
}
.icon-avatar.icon-avatar--select2 {
    display: inline-block;
}
.icon-avatar.red { background-color: @md-red; }
.icon-avatar.alt_red { background-color: @md-alt-red; }
.icon-avatar.pink { background-color: @md-pink; }
.icon-avatar.alt_pink { background-color: @md-alt-pink; }
.icon-avatar.purple { background-color: @md-purple; }
.icon-avatar.alt_purple { background-color: @md-alt-purple; }
.icon-avatar.deep_purple { background-color: @md-deep-purple; }
.icon-avatar.alt_deep_purple { background-color: @md-alt-deep-purple; }
.icon-avatar.indigo { background-color: @md-indigo; }
.icon-avatar.alt_indigo { background-color: @md-alt-indigo; }
.icon-avatar.blue { background-color: @md-blue; }
.icon-avatar.alt_blue { background-color: @md-alt-blue; }
.icon-avatar.light_blue { background-color: @md-light-blue; }
.icon-avatar.alt_light_blue { background-color: @md-alt-light-blue; }
.icon-avatar.cyan { background-color: @md-cyan; }
.icon-avatar.alt_cyan { background-color: @md-alt-cyan; }
.icon-avatar.teal { background-color: @md-teal; }
.icon-avatar.alt_teal { background-color: @md-alt-teal; }
.icon-avatar.green { background-color: @md-green; }
.icon-avatar.alt_green { background-color: @md-alt-green; }
.icon-avatar.light_green { background-color: @md-light-green; }
.icon-avatar.alt_light_green { background-color: @md-alt-light-green; }
.icon-avatar.lime { background-color: @md-lime; }
.icon-avatar.alt_lime { background-color: @md-alt-lime; }
.icon-avatar.yellow { background-color: @md-yellow; }
.icon-avatar.alt_yellow { background-color: @md-alt-yellow; }
.icon-avatar.amber { background-color: @md-amber; }
.icon-avatar.alt_amber { background-color: @md-alt-amber; }
.icon-avatar.orange { background-color: @md-orange; }
.icon-avatar.alt_orange { background-color: @md-alt-orange; }
.icon-avatar.deep_orange { background-color: @md-deep-orange; }
.icon-avatar.alt_deep_orange { background-color: @md-alt-deep-orange; }
.icon-avatar.brown { background-color: @md-brown; }
.icon-avatar.alt_brown { background-color: @md-alt-brown; }
.icon-avatar.grey { background-color: @md-grey; }
.icon-avatar.alt_grey { background-color: @md-alt-grey; }
.icon-avatar.blue_grey { background-color: @md-blue-grey; }
.icon-avatar.alt_blue_grey { background-color: @md-alt-blue-grey; }