.modal-content {
    border: 0;
    border-radius: 3px;
    box-shadow: 0 5px 20px rgba(0,0,0,.3);
}
.modal-header {
    background: @gray-lighter;
    @media (min-width: @screen-sm-min) {
        padding: @modal-title-padding * 2;
    }
    .close {
        margin-top: -10px;
    }
    .actions {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
.modal-title {
    line-height: @line-height-computed;
    small {
        font-size: @font-size-base;
        color: lighten(@gray, 20%);
    }
}
.modal-body {
    @media (min-width: @screen-sm-min) {
        padding: @modal-inner-padding * 2;
    }    
}
.modal-footer {
    text-align: left;
}

.close {
    cursor: pointer;
    display: inline-block;
    .transition(all .3s ease-in-out);
    height: 40px;
    padding: 5px;
    vertical-align: middle;
    &:hover, &:focus {
        i {
            .rgba-black(.1);
        }
    }
    i {
        .transition(all .3s ease-in-out);
        font-size: @font-size-h1;
        border-radius: 50%;
        padding: 0;
        line-height: 30px;
        width: 30px;
        .text-center;
    }
}
.modal {
    .form-actions {
        border-top: 1px solid @gray-light;
        padding-top: 15px;
    }
}

.iframe-container {
    padding-bottom: 110%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.iframe-container iframe,
.iframe-container object,
.iframe-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}