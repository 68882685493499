.table-responsive  {
    border: 0;
    margin: 0;
    overflow: visible !important; // Dient voor dropdown, anders wordt die afgekapt… so much for responsiveness?!
}
.table {
    background: transparent;
    .table-hover;
    display: table;
    margin: 0;
    width: 100%;
}
.table>thead>tr>th {
     color: @gray;
     border-color: @table-border-color;
     border-width: 1px;
}
.table>tbody >tr>td, .table .td {
    vertical-align: top;
}
.table>thead>tr>th {
    padding-bottom: 16px;
    padding-top: 16px;
}

.table>thead>tr>th:first-child,
.table>tbody>tr>th:first-child,
.table>tfoot>tr>th:first-child,
.table>thead>tr>td:first-child,
.table>tbody>tr>td:first-child,
.table>tfoot>tr>td:first-child,
.table>.tr .td:first-child {
    padding-left: 16px;
}
.tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    &:hover {
        background-color: @table-bg-hover;
    }
}

tr, .tr {
    cursor: pointer;

    .actions {
        position: absolute;
        right: 40px;
        .opacity(0);

        .ui-sortable-handle {
            background: transparent;
        }
    }
    .actions.left-column {
        right: auto;
        left: -40px;
    }
    &:hover {
        .actions {
            .opacity(1);
        }
    }
}

.td {
    border-bottom: 1px solid @table-border-color;
    padding: @table-cell-padding;
    display: table-cell;
    .table:last-child & {
        border: 0;
    }
}

td, .td {
    .avatar {
      .img-circle;
    }
    .actions, .dropdown {
        min-height: 40px;
        font-size: 0;
        display: inline-block;
    }
    .tooltip {
        white-space: nowrap;
    }
    strong {
        a {
            color: @gray-dark;
            font-weight: 500;
            &:hover {
                color: @gray-dark;
            }
        }
    }
}

tr.ui-sortable-helper {
    .actions:not(.left-column) {
        opacity: 0;
    }
}

.cell-avatar {
    width: 10%;
    white-space: nowrap;
}
.cell-avatar--xxs {
    width: 12px;
}
.cell-text {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.singleline {
        vertical-align: middle;
    }
}
.cell-text--no-overflow {
    max-width: none;
    overflow: visible;
    white-space: nowrap;
}
.cell-actions {
    width: 40px;
    text-align: right;
    white-space: nowrap;
}
.cell-actions-wide {
    width: 10%;
}
.row--inactive,
.row--inactive a {
    color: @gray-light;
}
.row--inactive {
    img {
        filter: gray;
        filter: grayscale(100%);
        .opacity(.5);
    }
}