.tab-nav {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);
    border-bottom: 1px solid darken(@gray-light, 5%);
    .list-unstyled;
   .text-center;
   padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: auto;
    // box-shadow: inset 0 -2px 0 0 darken(@gray-light, 5%);
    li {
        display: inline-block;
        line-height: @line-height-computed;
        vertical-align: top;
        font-weight: 500;
    }
    a {
        display: inline-block;
        color: @gray;
        text-transform: uppercase;
        position: relative;
        width: 100%;
        .transition(all .3s ease-in-out);
        padding: 15px;
        &:hover {
          color: @gray-darker;
        }
        &:after {
          content: "";
          height: 2px;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          .transition(all .3s ease-in-out);
          .scale(0);
        }
    }
    .active {
        a {
            color: @gray-darker;
            &:after {
                background: @brand-primary;
                .scale(1);
            }
        }
    }

}