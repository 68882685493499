.avatar {
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    line-height: 40px;
    letter-spacing: -1px;
    font-size: 17px;
    font-weight: 300;
    .text-center;
    background-size: contain !important;
}

.profile-menu .avatar {
    font-size: 19px;
    line-height: 50px;
}

.panel-intro .avatar {
    font-size: 28px;
    line-height: 72px;
    @media (min-width: @screen-sm-min) {
        font-size: 48px;
        line-height: 124px;
    }
}