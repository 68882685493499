.login {
    text-align: center;
    &:before {
        background: @brand-primary;
        height: 50%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        overflow: hidden;
    }
    &:after {
        content: "";
        vertical-align: middle;
        display: inline-block;
        width: 1px;
        height: 100%;
    }
}
.login-box {
    display: inline-block;
    vertical-align: middle;
    width: calc(~'100% - 60px');
    text-align: left;
    @media (min-width: @screen-sm-min) {
        width: 360px;
    }
    .panel-heading {
        background: darken(@brand-primary, 4%);
        color: #fff;
        text-transform: uppercase;
    }
}