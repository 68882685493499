.tooltip {
    font-weight: 500;
}
.tooltip-inner {
    // .rgba-black(.7);
    background: @gray-dark;
    padding: 6px 10px;
    max-width: 300px;
}
.tooltip-arrow {
    display: none;
}