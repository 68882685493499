.btn {
    // border: 0;
    font-weight: 500;
    border-radius: @border-radius-small;
    padding-left: @padding-large-horizontal;
    padding-right: @padding-large-horizontal;
    // padding: @padding-large-vertical @padding-large-horizontal;
    .transition(all .3s ease-in-out);
}

.btn-default {
    background: #fff;
    color: @gray-dark;
    box-shadow: 0 1px 0 rgba(0,0,0,.05);
    border: 1px solid rgba(0,0,0,.15);
    text-transform: uppercase;
    &:hover {
        background: #fff;
        color: @gray-dark;
        box-shadow: 0 1px 0 rgba(0,0,0,.1);
        border-color: #b8b8b8;
    }
}

.btn-submit {
    margin-right: 12px;
}
.btn-primary {
    box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
    text-transform: uppercase;
    &:hover {
        box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    }
    &:focus {
        box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
    }
}
.btn-round {
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    border-radius: 50%;
    height: 56px;
    width: 56px;
    .text-center;
    padding: 0;
    &:hover {
        box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
    }
    > i {
        font-size: 28px;
        position: relative;
        top: 13px;
        .transition(all .3s ease-in-out);
    }
    //&:hover {
    //    i {
    //        .rotate(-360deg);
    //    }
    //}
}
.btn-round.btn-sm {
    width: 40px;
    height: 40px;

    > i {
        font-size: 24px;
        position: relative;
        top: 7px;
        .transition(all .3s ease-in-out);
    }
}
.btn-icon {
    background: transparent;
    color: darken(@gray-light, 20%);
    display: inline-block;
    border: 0;
    cursor: pointer;
    height: 40px;
    padding: 5px 0;
    &:hover, &:focus {
        color: @gray-darker;
    }
    &:focus {
        i {
            .rgba-black(.1);
        }
    }
    > i {
        .transition(all .3s ease-in-out);
        font-size: @font-size-h1;
        border-radius: 50%;
        .text-center;
        padding: 0;
        line-height: 30px;
        width: 30px;
    }
}
.btn-add {
    background: @brand-accent;
    color: #fff;
    &:hover, &:focus {
        color: #fff;
    }
}

#main-header .fab {
    position: relative;

    ul {
        .list-unstyled;
        clear: both;
        .transition(opacity .3s);
        position: absolute;
        width: 40px;
        left: 0;
        top: 56px;
        margin: 0;
        z-index: 1000;
        .text-center;
        visibility: hidden;

        li {
            opacity: 0;
            .transition(all .3s ease);
            transform: scale(0) translateY(100%);
            margin-bottom: 12px;
            &:nth-child(1) {
                .transition(all .3s ease .1s);
            }
            &:nth-child(2) {
                .transition(all .3s ease .2s);
            }
        }
        .btn-round {
            height: 40px;
            width: 40px;
            > i {
                color: @gray-dark;
                font-size: 20px;
                top: 10px;
            }
        }
    }
    &.open {
        ul {
            li {
                opacity: 1;
                visibility: visible;
                transform: scale(1) translateY(0px);
            }
        }
    }
}

.fab {
    position: relative;

    ul {
        .list-unstyled;
        clear: both;
        .transition(opacity .3s);
        position: absolute;
        width: 56px;
        left: 0;
        bottom: 56px;
        margin: 0;
        z-index: 1000;
        .text-center;
        visibility: hidden;
        li {
            opacity: 0;
            .transition(all .3s ease);
            transform: scale(0) translateY(100%);
            margin-bottom: 12px;
            &:nth-child(1) {
                .transition(all .3s ease .2s);
            }
            &:nth-child(2) {
                .transition(all .3s ease .1s);
            }
        }
        .btn-round {
            height: 48px;
            width: 48px;
            i {
                color: @gray-dark;
                font-size: 22px;
                top: 12px;
            }
        }
    }
    &.open {
        ul {
            li {
                opacity: 1;
                visibility: visible;
                transform: scale(1) translateY(0px);
            }
        }
    }
}