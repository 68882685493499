*, :active, :hover {
    outline: 0 !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}
*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
}
a:hover, a:focus {
    text-decoration: none;
}

html, body {
    height: 100%;
}
body {
    background: @gray-lighter;
}

th, strong, label, dt {
    font-weight: 500;
}
.uc { text-transform: uppercase; }

h3 {
    small {
        font-size: @font-size-small;
        color: lighten(@gray, 20%);
    }
}

a[ng-click]{
    cursor: pointer;
}

.block-header {
    font-size: @font-size-base;
    line-height: @line-height-computed;
    color: lighten(@gray, 20%);
    margin-top: 0;
    padding: 0 16px;
    &:first-child {
        margin-top: 0;
    }
}

dt {
    padding: 4px 0;
    line-height: @line-height-computed;
    font-weight: 400;
    a {
        text-decoration: underline;
    }
}

dt, .secondary {
    font-size: @font-size-small;
    color: lighten(@gray, 20%);
}
.dl-horizontal dd {
    padding: 4px 0;
}

.fixed {
    position: fixed;
    margin: 0;
    z-index: 1000;
}
.bottomleft, .bottomright {
    bottom: 20px;
}
.bottomleft {
    left: 20px;
    @media (min-width: @screen-lg) {
        left: @navbar-width + 20;
    }
}
.bottomright {
    right: 20px;
}
.icon {
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
}
.dropdown {
    &.open {
        .btn-icon {
            i {
                color: @gray-darker;
                .rgba-black(.1);
            }
        }
        .tooltip {
            .opacity(0);
        }
    }
}
.dropdown-header {
    color: lighten(@gray, 20%);
}
.dropdown-menu {
//    display: block;
    border-radius: 0;
    .transition(all .15s ease-in-out);
    .scale(0);
    .opacity(0);
    transform-origin: 0 0;
    &.dropdown-menu-right {
        transform-origin: 100% 0;
    }
    .open & {
        .scale(1);
        .opacity(1);
    }
}

.actions {
    > li {
        display: inline-block;
        vertical-align: baseline;
        > a {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            position: relative;
            .transition(background .3s);
            &:hover {
                .rgba-black(0.1);
                > i {
                    color: @gray-darker;
                }
            }
            > i {
                .transition(color .3s);
                vertical-align: middle;
                color: darken(@gray-light, 20%);
                font-size: @font-size-h2;
                line-height: 32px;
                }
            }
        }
    > .open  > a {
        .rgba-black(0.1);
        > i {
            color: @gray-darker;
        }
    }
}

#main.affixed {
    @media (max-width: @screen-lg) {
        margin-top: 64px;
    }
}