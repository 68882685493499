
// ANGULAR/UI-SELECT //-------------------------------------------------------------------------------------------------
//.select2-container {
//    margin: 0;
//    position: relative;
//    display: inline-block;
//    /* inline-block for ie7 */
//    zoom: 1;
//    *display: inline;
//    vertical-align: middle;
//}

//.select2-container,
//.select2-drop,
//.select2-search,
//.select2-search input {
//    /*
//      Force border-box so that % widths fit the parent
//      container without overlap because of margin/padding.
//
//      More Info : http://www.quirksmode.org/css/box.html
//    */
//    -webkit-box-sizing: border-box; /* webkit */
//    -moz-box-sizing: border-box; /* firefox */
//    box-sizing: border-box; /* css3 */
//}

.select2-container .select2-choice {
    display: block;
    overflow: hidden;
    position: relative;

    height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;


    border: 1px solid @input-border;
    white-space: nowrap;
    text-decoration: none;

    border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.

    background-clip: padding-box;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    background-color: @input-bg;
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: #aaa;

    border-radius: 0 0 4px 4px;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
    margin-right: 26px;
    display: block;
    overflow: hidden;

    white-space: nowrap;

    text-overflow: ellipsis;
    text-align: left;
}

.select2-container .select2-choice abbr {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 24px;
    top: 8px;

    font-size: 1px;
    text-decoration: none;

    border: 0;
    cursor: pointer;
    outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
    display: inline-block;
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer;
}

.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 9998;
    /* styles required for IE to work */
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-drop {
    width: 100%;
    margin-top: -1px;
    position: absolute;
    z-index: 9999;
    top: 100%;

    background: @white;
    color: #444;
    border: 1px solid #aaa;
    border-top: 0;

    border-radius: 0 0 4px 4px;
}

.select2-drop-auto-width {
    border-top: 1px solid #aaa;
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: 4px;
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
    border-top: 1px solid #aaa;
    border-bottom: 0;

    border-radius: 4px 4px 0 0;
}

.select2-drop-active {
    border: 1px solid #ccc;
    border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
    border-top: 1px solid #5897fb;
}

.select2-container .select2-choice .select2-arrow {
    display: inline-block;
    width: 20px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.select2-container .select2-choice .select2-arrow b {
    border-color: #888 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
    content: "";
}

.select2-search {
    display: inline-block;
    width: 100%;
    min-height: 26px;
    margin: 0;
    padding-left: 4px;
    padding-right: 4px;

    position: relative;
    z-index: 10000;

    white-space: nowrap;
}

.select2-search input {
    width: 100%;
    height: auto !important;
    min-height: 26px;
    padding: 4px 20px 4px 5px;
    margin: 0;

    outline: 0;
    font-family: sans-serif;
    font-size: 1em;

    border: 1px solid #aaa;
    border-radius: 0;

    -webkit-box-shadow: none;
    box-shadow: none;

    background: #fff;
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 4px;
}

.select2-search input.select2-active {
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border: 1px solid #ccc;
    outline: none;

    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.select2-dropdown-open .select2-choice {
    border-bottom-color: transparent;
    -webkit-box-shadow: 0 1px 0 #fff inset;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    background-color: #fff;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border: 1px solid #ccc;
    border-top-color: transparent;
}

.select2-dropdown-open .select2-choice .select2-arrow {
    background: transparent;
    border-left: none;
    filter: none;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
    background-position: -18px 1px;
}

/* results */
.select2-results {
    max-height: 200px;
    padding: 0 0 0 4px;
    margin: 4px 4px 4px 0;
    //position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-results ul.select2-result-sub {
    margin: 0;
    padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label { padding-left: 20px }
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 40px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 60px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 80px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 100px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 110px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 120px }

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none;
    text-align: left;
    padding: 6px;
}

.select2-results li.select2-result-with-children > .select2-result-label {
    font-weight: bold;
}

.select2-results .select2-result-label {
    padding: 3px 7px 4px;
    margin: 0;
    cursor: pointer;

    min-height: 1em;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.select2-results .select2-highlighted {
    background: #ddd;
}
.ui-select-choices-row:hover {
    background: #3875d7;
    color: #fff;
}

.select2-results li em {
    background: #feffde;
    font-style: normal;
}

.select2-results .select2-highlighted em {
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: #fff;
    color: #000;
}


.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background: #f4f4f4;
    display: list-item;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
    //color: #666;
    //background: #f4f4f4;
    display: list-item;
    cursor: default;
}
.select2-results .select2-disabled {
    //background: #f4f4f4;
    display: list-item;
    cursor: default;
}
.select2-results .select2-optgroup {
    font-weight: 500;
}

.select2-results .select2-selected {
    display: none;
}

.select2-more-results.select2-active {
    background: #f4f4f4 url('select2-spinner.gif') no-repeat 100%;
}

.select2-more-results {
    background: #f4f4f4;
    display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
    background-color: #f4f4f4;
    background-image: none;
    border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
    display: none;
}


/* multiselect */

.select2-container-multi .select2-choices {
    .form-control;
    //height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    //margin: 0;
    padding: 0 0 4px 0;
    position: relative;
    height: auto;
    min-height: @input-height-base;

    //
    //cursor: text;
    overflow: hidden;
    //
    //font-size: @font-size-base;
    //line-height: @line-height-base;
    //color: @input-color;
    //
    ////border: 1px solid @input-border;
    //white-space: nowrap;
    //text-decoration: none;

    //border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
    //background-color: #fff;
}

.select2-locked {
    padding: 3px 5px 3px 5px !important;
}

.select2-container-multi.select2-container-active .select2-choices {
    outline: none;
}
.select2-container-multi .select2-choices li {
    float: left;
    list-style: none;
}
.select2-container-multi .select2-choices .select2-search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
    //padding: 5px;
    //margin: 1px 0;
    //
    //font-family: sans-serif;
    //font-size: 100%;
    //color: #666;
    //outline: 0;
    //border: 0;
    .form-control;
    border: none;
    border-radius: none;
    box-shadow: none;
    margin: 0;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
}

.select2-default {
    color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
    .btn;
    .btn-default;
    .btn-xs;
    margin: 4px 0 0 4px;
    //padding: 3px 5px 3px 18px;
    //margin: 3px 0 3px 5px;
    //position: relative;
    //
    //line-height: 13px;
    //color: @gray-dark;
    //cursor: default;
    //border: 1px solid @gray;
    //
    //border-radius: 3px;
    //
    //background-clip: padding-box;
    //
    //-webkit-touch-callout: none;
    //-webkit-user-select: none;
    //-moz-user-select: none;
    //-ms-user-select: none;
    //user-select: none;
    //
    //background-color: @white;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
    cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #d4d4d4;
}

.select2-search-choice-close {
    float: right;
    font-weight: bold;
    color: @gray-dark;
    text-shadow: 0 1px 0 @white;
    opacity: .2;
    font-size: 1.6em;
    line-height: 0.75;
    margin-left: 5px;
}
.select2-search-choice-close:after {
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    content: '\f136';
}

.select2-container-multi .select2-search-choice-close {
    left: 3px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
    background-position: right -11px;
}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
    background-position: right -11px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
    //padding: 3px 5px 3px 5px;
    border: 1px solid #ddd;
    background-image: none;
    background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
    display: none;
    background: none;
}
/* end multiselect */


.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}
/* Retina-ize icons */
.ui-select-highlight {
    font-weight: bold;
}

.ui-select-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

/* Select2 theme */

/* Mark invalid Select2 */
.ng-dirty.ng-invalid > a.select2-choice {
    border-color: #D44950;
}

.select2-result-single {
    padding-left: 0;
}

.select2-locked > .select2-search-choice-close{
    display:none;
}

.select-locked > .ui-select-match-close{
    display:none;
}

body > .select2-container.open {
    z-index: 9999; /* The z-index Select2 applies to the select2-drop */
}

.select2-choices {
    position: relative;
}
.ui-select-multiple input.ui-select-search {
    width: 100% !important;
    position: absolute;
}

// SELECT2 // ----------------------------------------------------------------------------------------------------------
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: @brand-primary;
}
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    border-color: @input-border;
    border-radius: @border-radius-small;
    height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 5px;
    top: 5px;
}
.select2-container--default .select2-selection--multiple {
    border-color: @input-border;
}
.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: @brand-primary;
}
