.transition(@value) {
    -webkit-transition: @value;
    -moz-transition: @value;
    transition: @value;
}

.opacity(@opacity) {
  opacity: @opacity;
  @opperc: @opacity * 100;
  filter: ~"alpha(opacity=@{opperc})";
}
.rgba-white(@opacity) {
    background: rgba(255,255,255,@opacity);
}
.rgba-black(@opacity) {
    background: rgba(0,0,0,@opacity);
}
.scale(@value) {
    -webkit-transform: scale(@value);
    -ms-transform: scale(@value);
    -o-transform: scale(@value);
    transform: scale(@value);
}
.rotate(@value) {
    -webkit-transform: rotate(@value);
    -ms-transform: rotate(@value);
    -o-transform: rotate(@value);
    transform: rotate(@value);
}

.gradient(@color1, @color2) {
    background: -webkit-linear-gradient(left,@color1,@color2);
    background: -o-linear-gradient(right,@color1,@color2);
    background: -moz-linear-gradient(right,@color1,@color2);
    background: linear-gradient(to right, @color1, @color2);
}