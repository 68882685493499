@event-sidebar: 160px;
@event-size: 32px;
@event-padding: 6px;

#weekly-agenda-index #main-header h1,
#agenda-index #main-header h1 {
    display: none;
}

.datenav {
    display: inline-block;
    //width: calc(~'100% - 45px');

    a {
        .h1;
        color: @breadcrumb-bg;
        display: inline-block;
        font-weight: 400;
        margin: 0;
        line-height: @line-height-computed * 2;
        height: @line-height-computed * 2;
        position: relative;
        z-index: 10;
        width: auto;
    }
}
.datenav .datenav-nav {
    width: 20px;
    text-align: center;
}

.timeline-container {
    display: table;
    position: relative;
    margin-top: @line-height-computed;
    width: 100%;
}
.timeline-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: fade(#000, 50%);
    display: none;
}
.timeline-loading.timeline-loading--in {
    display: block;
}
.timeline-loading > i {
    color: fade(#fff, 80%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.timeline {
    display: table;
    position: relative;
    width: 100%;
    font-size: @font-size-small;
    line-height: @line-height-computed;
}
.timeline-hours {
    .list-unstyled;
    margin: 0;
    position: absolute;
    left: @event-sidebar;
    right: 0;
    top: -@line-height-computed;
    bottom: 0;
    z-index: 0;
}
.timeline-hours:before {
    background: @table-border-color;
    content: '';
    display: block;
    bottom: 0;
    // height: 100%;
    width: 1px;
    left: 0;
    margin-left: -1px;
    top: @line-height-computed;
    position: absolute;
}
.timeline-hours > li {
    font-size: 10px;
    color: lighten(@gray, 20%);
    float: left;
    width: 8.333333333%;
    height: 100%;
    position: relative;
}
.timeline-hours > li:after {
    background: @table-border-color;
    content: '';
    display: block;
    bottom: 0;
    // height: 100%;
    width: 1px;
    left: 100%;
    // margin-left: -1px;
    top: @line-height-computed;
    position: absolute;
}
.timeline-hours > li:last-child {
    .text-hide;
}
.timeline-hours > li > span {
    width: 60px;
    .text-center;
    position: absolute;
    right: -30px;
    top: 0;
}
.timeline-line {
    padding: (@event-size / 4) 0;
    border-bottom: 1px solid @table-border-color;
}
.timeline-line:last-child {
    border: 0;
}
.timeline-line .line-vehicle {
    line-height: @event-size;
    white-space: nowrap;
    width: @navbar-width;
    padding: (@event-size / 4) (@event-size / 2);
    text-align: right;
    width: @event-sidebar;
    position: absolute;
}
.timeline-line > .line-title {
    font-weight: 500;
    line-height: (@event-size / 2);
    padding: (@event-size / 4) (@event-size / 2);
}
.timeline-line > .line-title > span {
    color: lighten(@gray, 20%);
}

.timeline-events {
    height: @event-size + (@event-size / 2);
    position: relative;
    margin-left: @event-sidebar;
    padding: (@event-size / 4) 0;
    z-index: 20;
    border-bottom: 1px solid @table-border-color;
}
.timeline-events:last-child {
    border: 0;
}
.timeline-events .events-event {
    background: fade(@md-grey, 50%);
    height: @event-size;
    color: #fff;
    position: absolute;
    .text-center;
    padding: @event-padding 0;
    z-index: 20;
    cursor: pointer;
    .transition(box-shadow .2s ease-in-out);
}
.timeline-events .timeline-events-group:hover .events-event {
    box-shadow: inset 0 @event-size 0 rgba(0, 0, 0, .1);
}

.timeline-events .events-event.events-event--prevDay,
.timeline-events .events-event.events-event--nextDay {
    border-radius: 0;
}
.timeline-events .events-event.events-event--start.events-event--end {
    border-radius: 16px;
}
.timeline-events .events-event.events-event--start.events-event--end.events-event--nextDay {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.timeline-events .events-event.events-event--start {
    border-radius: 16px 0 0 16px;
}
.timeline-events .events-event.events-event--end,
.timeline-events .events-event.events-event--prevDay.events-event--end {
    border-radius: 0 16px 16px 0;
}

.timeline-events .events-event.events-event--prevDay.events-event--start {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.timeline-events .events-event.events-event--nextDay {
    border-radius: 0;
}

.timeline-events .events-event.events-event--prevDay .event-time.event-time--start,
.timeline-events .events-event.events-event--nextDay .event-time.event-time--end {
    display: none;
}

.events-event > .event-location,
.events-event > .event-time {
    left: 0;
    width: @event-size;
    .text-center;
    margin-left: -@event-size / 2;
    position: absolute;
    font-weight: 500;
    letter-spacing: -.5pt;
    text-indent: -1px;

    // Voorlopig
    margin-left: 0;
    left: @event-padding;
}

.events-event:hover > .event-time > span {
    transform: translate3d(0, 0, 0);;
    .opacity(1);
}
.events-event > .event-location,
.events-event > .event-location.event-location--stop {
    background: fade(@gray-darker, 20%);
    border-radius: 10px;
    top: @event-padding;
    text-transform: uppercase;
}
.events-event > .event-location { display: none; }
.events-event.events-event--start > .event-location.event-location--start,
.events-event.events-event--end:not(.events-event--nextDay) > .event-location.event-location--stop {
    display: block;
}
.events-event > .event-location.event-location--start { display: block; }

.events-event:not(.events-event--start):not(.events-event--nextDay) .event-location.event-location--start,
.events-event:not(.events-event--start):not(.events-event--nextDay) .event-time.event-time--start {
    margin-left: -(@event-padding + (@event-size / 2));
}
.events-event:not(:last-child) .event-time.event-time--end {
    margin-right: -(@event-padding + (@event-size / 2));
}
.events-event.events-event--prevDay.events-event--end > .event-location.event-location--start {
    display: none;
}

.events-event > .event-location.event-location--stop {
    margin-right: 0;
    margin-left: auto;
    left: auto;
    right: @event-padding;
}

.events-event > .event-time {
    color: @gray;
    top: -@line-height-computed;
    overflow: hidden;
}
.events-event > .event-time.event-time--end {
    margin-right: 0;
    margin-left: auto;
    left: auto;
    right: @event-padding;
}
.events-event > .event-time > span {
    .rgba-white(.7);
    display: block;
    .transition(all .2s ease-in-out);
    transform: translate3d(0, 100%, 0);;
    .opacity(0);
}
.events-event > .event-title {
    font-size: @font-size-base;
//    margin-left: 22px;
//    margin-right: @event-size + @event-padding;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.events-event.events-event--start:not(.events-event--prevDay) .event-title {
    margin-left: 22px;
}

.events-event.events-event--end:not(.events-event--nextDay) .event-title {
    margin-right: 22px;
}

.timeline .timeline-events .events-event,
.drive-item .drive:after {
    transition: all 200ms ease-in-out;
}

.timeline .events-event--red,
.drive.drive--red:after {
    background: fade(@md-red, 50%);
}
.timeline .events-event--pink,
.drive.drive--pink:after {
    background: fade(@md-pink, 50%);
}
.timeline .events-event--purple,
.drive.drive--purple:after {
    background: fade(@md-purple, 50%);
}
.timeline .events-event--deep_purple,
.drive.drive--deep_purple:after {
    background: fade(@md-deep-purple, 50%);
}
.timeline .events-event--indigo,
.drive.drive--indigo:after {
    background: fade(@md-indigo, 50%);
}
.timeline .events-event--blue,
.drive.drive--blue:after {
    background: fade(@md-blue, 50%);
}
.timeline .events-event--light_blue,
.drive.drive--light_blue:after {
    background: fade(@md-light-blue, 50%);
}
.timeline .events-event--cyan,
.drive.drive--cyan:after {
    background: fade(@md-cyan, 50%);
}
.timeline .events-event--teal,
.drive.drive--teal:after {
    background: fade(@md-teal, 50%);
}
.timeline .events-event--green,
.drive.drive--green:after {
    background: fade(@md-green, 50%);
}
.timeline .events-event--light_green,
.drive.drive--light_green:after {
    background: fade(@md-light-green, 50%);
}
.timeline .events-event--lime,
.drive.drive--lime:after {
    background: fade(@md-lime, 50%);
}
.timeline .events-event--yellow,
.drive.drive--yellow:after {
    background: fade(@md-yellow, 50%);
}
.timeline .events-event--amber,
.drive.drive--amber:after {
    background: fade(@md-amber, 50%);
}
.timeline .events-event--orange,
.drive.drive--orange:after {
    background: fade(@md-orange, 50%);
}
.timeline .events-event--deep_orange,
.drive.drive--deep_orange:after {
    background: fade(@md-deep-orange, 50%);
}
.timeline .events-event--brown,
.drive.drive--brown:after {
    background: fade(@md-brown, 50%);
}
.timeline .events-event--grey,
.drive.drive--grey:after {
    background: fade(@md-grey, 50%);
}
.timeline .events-event--blue_grey,
.drive.drive--blue_grey:after {
    background: fade(@md-blue-grey, 50%);
}

.drive.drive--planned:after,
.timeline-events .events-event--planned {
    background: @md-grey;
}

.timeline-events .events-event--planned.events-event--red,
.drive.drive--red:after {
    background: @md-red;
}
.timeline-events .events-event--planned.events-event--pink,
.drive.drive--pink.drive--planned:after {
    background: @md-pink;
}
.timeline-events .events-event--planned.events-event--purple,
.drive.drive--purple.drive--planned:after {
    background: @md-purple;
}
.timeline-events .events-event--planned.events-event--deep_purple,
.drive.drive--deep_purple.drive--planned:after {
    background: @md-deep-purple;
}
.timeline-events .events-event--planned.events-event--indigo,
.drive.drive--indigo.drive--planned:after {
    background: @md-indigo;
}
.timeline-events .events-event--planned.events-event--blue,
.drive.drive--blue.drive--planned:after {
    background: @md-blue;
}
.timeline-events .events-event--planned.events-event--light_blue,
.drive.drive--light_blue.drive--planned:after {
    background: @md-light-blue;
}
.timeline-events .events-event--planned.events-event--cyan,
.drive.drive--cyan.drive--planned:after {
    background: @md-cyan;
}
.timeline-events .events-event--planned.events-event--teal,
.drive.drive--teal.drive--planned:after {
    background: @md-teal;
}
.timeline-events .events-event--planned.events-event--green,
.drive.drive--green.drive--planned:after {
    background: @md-green;
}
.timeline-events .events-event--planned.events-event--light_green,
.drive.drive--light_green.drive--planned:after {
    background: @md-light-green;
}
.timeline-events .events-event--planned.events-event--lime,
.drive.drive--lime.drive--planned:after {
    background: @md-lime;
}
.timeline-events .events-event--planned.events-event--yellow,
.drive.drive--yellow.drive--planned:after {
    background: @md-yellow;
}
.timeline-events .events-event--planned.events-event--amber,
.drive.drive--amber.drive--planned:after {
    background: @md-amber;
}
.timeline-events .events-event--planned.events-event--orange,
.drive.drive--orange.drive--planned:after {
    background: @md-orange;
}
.timeline-events .events-event--planned.events-event--deep_orange,
.drive.drive--deep_orange.drive--planned:after {
    background: @md-deep-orange;
}
.timeline-events .events-event--planned.events-event--brown,
.drive.drive--brown.drive--planned:after {
    background: @md-brown;
}
.timeline-events .events-event--planned.events-event--grey,
.drive.drive--grey.drive--planned:after {
    background: @md-grey;
}
.timeline-events .events-event--planned.events-event--blue_grey,
.drive.drive--blue_grey.drive--planned:after {
    background: @md-blue-grey;
}

.timeline-events .events-event.events-event--rest,
.drive.drive--isRest:after,
.drive.drive--planned.drive--isRest:after {
    #gradient > .striped(rgba(255,255,255,.35));
    background-size: 40px 40px;
}