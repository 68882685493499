#weekly-agenda {
    .day-title {
        margin-top: 0;

        a {
            color: @text-color;
        }
    }

    #main .container {
        width: 100%;
        max-width: inherit !important;
    }

    /**
     * GEEN SIDEBAR
     */
    //#header {
    //    @media (min-width: @screen-lg) {
    //        position: relative;
    //        width: 100%;
    //        padding-left: inherit;
    //    }
    //}
    //
    //#sidebar {
    //    @media (min-width: @screen-lg) {
    //        -webkit-transform: translate3d(-@navbar-width,0,0) !important;
    //        transform: translate3d(-@navbar-width,0,0) !important;
    //        .opacity(0);
    //    }
    //}
    //
    //#main-header {
    //    @media (min-width: @screen-lg) {
    //        position: relative;
    //        width: 100%;
    //        padding-left: inherit;
    //        top: auto !important;
    //    }
    //}
    //
    //#content {
    //    @media (min-width: @screen-lg) {
    //        padding-top: inherit !important;
    //        padding-left: inherit;
    //    }
    //}

    /**
     * SCHTYLING
     */
    .row--week {
        margin-left: -(@padding-large-horizontal / 2);
        margin-right: -(@padding-large-horizontal / 2);
    }
    .col-md-day {
        .make-sm-column(2);
        float: left;
        padding-left: @padding-large-horizontal / 2;
        padding-right: @padding-large-horizontal / 2;
        width: 100%;

        @media (min-width: @screen-sm-min) {
            width: 100% / 7 !important;
        }
    }


    .calendar-sidebar {
        width: 100%;
        display: inline-block;
    }

    .table > .tr .td:first-child {
        padding-left: 8px;
        padding-right: 0;
    }

    tr, .tr {
        cursor: default;
    }

    .tr:hover {
        background: inherit;
    }

    .panel-body {
        padding: (@padding-large-horizontal / 2) (@padding-large-horizontal / 2) 0 (@padding-large-horizontal / 2);
    }

    .tour-title {
        margin: 0;
        .text-overflow();
    }
    //.tour-title-part {
    //    .text-overflow();
    //}

    .line-title {
        .text-overflow();
    }

    .tour-wrapper,
    .tour-wrapper .tr {
        cursor: pointer;
    }

    .panel--tour,
    .line-tourWrapper {
        .table .tr:first-child .td {
            padding-bottom: 0;
        }

        .table .tr:last-child .td {
            padding-top: 0;
        }

    }

    .line-tourWrapper {
        border-bottom: 1px solid @table-border-color;
        padding-bottom: @padding-base-vertical;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        .time {
            background: @white;
        }
        &:first-child .time {
            background: transparent;
        }
    }
}