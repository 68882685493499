.stateSwitcher {
    display: inline-block;
}
.stateSwitcher .stateSwitcher-initial {
    display: block;
}
.stateSwitcher.stateSwitcher--switched .stateSwitcher-initial {
//    display: none;
}
.stateSwitcher .stateSwitcher-switched {
//    display: none;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    float: right;
}
.stateSwitcher.stateSwitcher--switched .stateSwitcher-switched {
//    display: block;
}
.stateSwitcher-row.stateSwitcher-row--active {
    background-color: @table-bg-hover;
}
.stateSwitcher-cell {
    position: relative;
}
.stateSwitcher-cell .stateSwitcher--switched .stateSwitcher-switched {
    position: absolute;
//    display: inline-block;
    opacity: 1;
    visibility: visible;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    height: auto;
    padding: @table-cell-padding;
    background: @table-bg-hover;

    -webkit-transition: width 100ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
       -moz-transition: width 100ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
         -o-transition: width 100ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
            transition: width 100ms cubic-bezier(0.215, 0.610, 0.355, 1.000); /* easeOutCubic */
}
.stateSwitcher-cell .stateSwitcher--switched .stateSwitcher-switched form {
    position: absolute;
    right: @table-cell-padding;
    top: 50%;
    transform: translate(0, -50%);
}
