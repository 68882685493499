// Non-modular css sucks, ik moet nu deze klasse overschrijven :/
.datepicker {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);

    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;

    opacity: 1;
    z-index: 9999 !important;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
    display: block;
    border-radius: 0;
    .transition(none);
    .scale(1);
    .opacity(1);
    transform-origin: 100% 0;
    .open & {
        .scale(1);
        .opacity(1);
    }
}
.bootstrap-datetimepicker-widget > .row {
    margin: 0;
}