.empty-state,
#empty-state {
    margin: 0 auto;
    max-width: @screen-xs;
    .text-center;
    i {
        color: darken(@gray-light, 10%);
    }
}
#empty-state,
.emptyState {
    margin: 0 auto;
    //    max-width: @screen-xs;
    .text-center;
}
.emptyState .td {
    padding: 30px;
    background-size: cover;
    position: relative;
}
.emptyState .td:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    .rgba-black(.4);
    z-index: 5;
}
.emptyState .td h2 {
    margin: 0;
}
.emptyState .td h2,
.emptyState .td i {
    color: #fff;
    position: relative;
    z-index: 20;
}
#empty-state i,
.emptyState .emptyState-icon {
    color: darken(@gray-light, 10%);
}
.emptyState.emptyState--success .emptyState-icon {
    color: @brand-success;
}

.emptyState.emptyState--success .emptyState-icon--success.emptyState-icon i {
    color: #fff;
    font-size: 84px;
    margin-bottom: 15px;
    line-height: 1;
}
.emptyState.emptyState--error .emptyState-icon--error.emptyState-icon {
    overflow: hidden;
    display: inline-block;
    height: 230px;
    width: 230px;
    position: relative;
}
.emptyState.emptyState--error .emptyState-icon--error.emptyState-icon img {
    .img-circle;
}
.emptyState.emptyState--error .emptyState-icon--error.emptyState-icon i {
    color: @panel-bg;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 10%);
    font-size: 200px;
    line-height: 1;
}
