#header {
    background: @brand-primary-darker;
    height: @navbar-height;
    position: relative;
    z-index: 30;
    width: 100%;
    .transition(padding .3s ease-in-out);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (min-width: @screen-lg) {
        position: fixed;
        top: 0;
        width: 100%;
        padding-left: @navbar-width;
    }
    .container {
        padding-right: 10px;
    }
}

.header-inner {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      display: inline-block;
      vertical-align: top;
      > a {
        color: #fff;
        display: block;
        padding: 15px;
      }
    }
    .logo {
        font-weight: 500;
        text-transform: uppercase;
        line-height: @line-height-computed;
        .pull-left;
        a {
            padding: 8px 0;
        }
    }
}


#table-search {
    position: absolute;
    top: -64px;
    left: 120px;
    right: 120px;
    height: 64px;
    .transition(all .3s ease-in-out);
    .opacity(0);
    z-index: 20;
    .search-toggled & {
        top: -12px;
        .opacity(1);
        @media (min-width: @screen-lg) {
            top: -12px;
        }
    }
    .search-toggled.affix & {
        top: 0;
    }
    @media (max-width: @screen-sm-min) {
        background: @brand-primary;
    }
    @media (min-width: @screen-sm-min) {
        .text-center;
    }
    @media (min-width: @screen-lg) {
        top: -64px;
        left: 120px;
    }
    .affix & {
        top: -64px;
    }

    input[type=text] {
        display: inline-block;
        border: 0;
        height: 36px;
        padding: 0 10px 0 40px;
        margin: 14px 0 14px 15px;
        width: calc(~'100% - 65px');
        color: #fff;
        .rgba-white(.2);
        border-radius: @border-radius-small;
        @media (min-width: @screen-sm-min) {
            width: 500px;
        }
        &+i {
            color: #fff;
            font-size: 20px;
            position: absolute;
            left: 15px;
            vertical-align: middle;
            top: 12px;
            padding: 5px 10px;
            line-height: 30px;
            height: 30px;
            vertical-align: top;
            @media (min-width: @screen-sm-min) {
                left: auto;
                margin-left: -500px;
            }
        }

        &:hover {
            .rgba-white(.4);
        }
        &:focus {
            &::-webkit-input-placeholder {
                color:  lighten(@gray, 20%);
            }
            color: @gray-dark;
            .rgba-white(1);
            &+i {
                color: @gray-dark;
            }
        }
        &::-webkit-input-placeholder {
            color:    #fff;
        }
    }
}

#table-search-close {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    .transition(all .3s ease-in-out);
    z-index: 20;
    height: 40px;
    padding: 5px;
    vertical-align: middle;
    &:hover, &:focus {
        i {
            .rgba-black(.1);
        }
    }
    i {
        .transition(all .3s ease-in-out);
        font-size: @font-size-h1;
        border-radius: 50%;
        padding: 0;
        line-height: 30px;
        width: 30px;
        .text-center;
    }
}

.top-menu {
    list-style: none;
    padding: 0;
    i {
        .transition(all .3s ease-in-out);
        border-radius: 50%;
        line-height: 30px;
        font-size: 20px;
        .text-center;
        height: 30px;
        width: 30px;
    }
    > li {
      display: inline-block;
      > a {
        color: #fff;
        display: block;
        padding: 10px 0;
        &:hover, &:focus {
            i {
                .rgba-black(.1);
            }
        }
      }
    }
}

#weekly-agenda-index .header-actions,
#agenda-index .header-actions {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}