.calendar-app {
    min-height: 96px;
    display: table;
    width: 100%;
}
.calendar-wrap {
    display: table-row;
}
// Calendar sidebar
// --------------------------------------------------
.panel--tour {
    max-width: inherit;
}
.calendar-sidebar, .calendar-drop {
    display: table-cell;
    vertical-align: top;
    border-bottom: 0;
}
.calendar-sidebar {
    position: relative;
    width: 30%;
    border-right: 1px solid @table-border-color;
}
.calendar-sidebar .driver-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.driver-list.is-empty {
    min-height: 56px;
    position: relative;
}
.driver-list.is-empty:after {
    content: 'Chauffeur';
    color: #999;
    position: absolute;
    left: 57px;
    top: 19px;
}
.driver-list .driver-list-item {
    border-bottom: 1px solid @table-border-color;
    padding: @table-cell-padding;
    position: relative;
}

.driver-list .driver-list-item.ui-sortable-helper .close {
    display: none;
}
.driver-list .driver-list-item.ui-sortable-helper {
    display: block !important;
}
.driver-list .ui-sortable-placeholder {
    display: block !important;
    background: @table-bg-hover;
    box-shadow: inset 0 -1px 0 @gray-lighter;
    visibility: visible !important;
    position: relative;
}
.driver-list.is-empty:before,
.avatar-select .select2-default:before,
.driver-list .ui-sortable-placeholder:before {
    background: @gray-lighter;
    content: '';
    position: absolute;
    top: 10px;
    left: 8px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.avatar-select .select2-default:before {
    top: 5px;
}
.avatar-select .select2-default .select2-chosen {
    margin-left: 48px;
    margin-top: 9px;
    margin-right: 0;
}

.driver-list-item.gu-mirror {
    display: block;
    width: 220px;
    background: @white;
}

.calendar-sidebar .driver-list .driver-list-item:last-child { border-bottom: none; }
.calendar-sidebar .select2-container .select2-choice,
.line-panel .select2-container .select2-choice {
    border: none;
    overflow: visible;
}
.calendar-sidebar .select2-container .select2-choice {
    border-bottom: 1px solid @table-border-color;
}
.calendar-sidebar .select2-container-active .select2-choice,
.line-panel .select2-container-active .select2-choice { box-shadow: none; }
.calendar-sidebar .select2-drop,
.line-panel .select2-drop {
    top: 0 !important;
}
.calendar-sidebar .direction-up .select2-drop,
.line-panel .direction-up .select2-drop {
    bottom: 0 !important;
    top: auto !important;
    border-radius: 4px 4px 0 0;
}
.line-panel .select2-drop {
    width: 200% !important;
}
.calendar-sidebar .select2-drop-active,
.line-panel .select2-drop-active {
    padding-top: @table-cell-padding;
    border-top: 1px solid #ccc;
}

.calendar-sidebar .select2-container .select2-choice > .select2-chosen {
    overflow: visible;
}
.calendar-sidebar .select2-results,
.calendar-sidebar .select2-results .select2-result-label {
    padding: 0;
    margin: 4px 0;
}

.calendar-sidebar .td.cell-avatar {
    padding-left: 8px !important;
    padding-right: 0;
}

.ui-select-cell {
    display: inline-block;
    vertical-align: top;
}
.ui-select-cell.ui-select-cell--avatar {
    margin-right: 5px;
}

.ui-select-cell.ui-select-cell--text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
}

.line-driver .ui-select-dropdown.select2-drop-active {
    right: 0;
}

//.calendar-sidebar .vehicle-selection {
//    position: absolute;
//    top: 0;
//    width: 100%;
//}
.calendar-sidebar .empty-state--drivers {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
}
.avatar-select .select2-choice,
.vehicle-selection .select2-choice {
    height: 50px;
}

.calendar-detail .tour-panel:last-child {
    margin-bottom: 0;
    border-bottom: none;
}
.calendar-detail .tour-title {
    margin-top: 5px;
}
.calendar-detail .tour-title .zmdi {
    color: lighten(@gray, 20%);
}

.calendar-detail .tour-legend {
    font-weight: normal;
    margin: 0 0 5px 0;
    color: lighten(@gray, 20%);
}

.tour-waypoints {
    list-style: none;
    padding-left: 14px;
    margin: 0;
    position: relative;
}
.tour-waypoints:before {
    .icon;
    content: '\f301';
    vertical-align: middle;
    position: absolute;
    top: 3px;
    left: 0;
}
.tour-waypoints.tour-waypoints--disembark:before {
    content: '\f2ff';
}

.tour-waypoints li {
    display: inline;
}
.tour-waypoints li:before {
    content: " — ";
    font-weight: normal;
}

.tour-waypoints li:first-child:before {
    content: "";
}

.line-panel > .row {
    margin: 0;
    //padding: 5px 0 0 0;
    position: relative;
}
.line-panel .time {
    position: absolute;
    top: -9px;
    left: 10px;
    color: @gray;
    z-index: 1;
}

.line-driver,
.line-vehicle {
    padding: 0;
}
.line-driver {
    border-right: 1px solid @table-border-color
}

//.line-panel .row:last-child {
//    //margin-bottom: 0px;
//    border: none;
//}

//.line-panel .row .col-sm-6 {
////    margin: 0;
//    padding: 0;
//}
//.line-panel .row .col-sm-6:first-child {
//
//}
//
//.line-panel .row .col-md-4 {
//    border-bottom: 1px solid @table-border-color;
//}

.line-panel .select2-container .select2-choice > .select2-chosen { overflow: visible; }

@drive-size: 32px;
@drive-padding: 8px;
@drive-radius: @drive-size + (@drive-padding * 2) / 2;

.drive .driver figure,
.drive .vehicle figure,
.select2-results figure {
    background: @gray-light;
    height: @drive-size;
    font-size: @font-size-h1;
    .text-center;
    color: #fff;
    line-height: @drive-size;
    width: @drive-size;
    border-radius: 50%;
    vertical-align: middle;
    .text-center;
}

.ui-sortable-handle {
    background: @white;
}

form[name=tourForm] .tab-nav {
    margin: -(@modal-inner-padding * 2) -(@modal-inner-padding * 2) @padding-base-horizontal -(@modal-inner-padding * 2);
    box-shadow: none;
}

.purple {
    background-color: #9960e9
}
.red {
    background-color: #ed4559
}
.yellow {
    background-color: #f1ee57
}
.green {
    background-color: #48984a
}
.blue {
    background-color: #4ab9d7
}

figure.red { background-color: @md-red; }
figure.pink { background-color: @md-pink; }
figure.purple { background-color: @md-purple; }
figure.deep_purple { background-color: @md-deep-purple; }
figure.indigo { background-color: @md-indigo; }
figure.blue { background-color: @md-blue; }
figure.light_blue { background-color: @md-light-blue; }
figure.cyan { background-color: @md-cyan; }
figure.teal { background-color: @md-teal; }
figure.green { background-color: @md-green; }
figure.light_green { background-color: @md-light-green; }
figure.lime { background-color: @md-lime; }
figure.yellow { background-color: @md-yellow; }
figure.amber { background-color: @md-amber; }
figure.orange { background-color: @md-orange; }
figure.deep_orange { background-color: @md-deep-orange; }
figure.brown { background-color: @md-brown; }
figure.grey { background-color: @md-grey; }
figure.blue_grey { background-color: @md-blue-grey; }
figure.alt_red { background-color: @md-alt-red; }
figure.alt_pink { background-color: @md-alt-pink; }
figure.alt_purple { background-color: @md-alt-purple; }
figure.alt_deep_purple { background-color: @md-alt-deep-purple; }
figure.alt_indigo { background-color: @md-alt-indigo; }
figure.alt_blue { background-color: @md-alt-blue; }
figure.alt_light_blue { background-color: @md-alt-light-blue; }
figure.alt_cyan { background-color: @md-alt-cyan; }
figure.alt_teal { background-color: @md-alt-teal; }
figure.alt_green { background-color: @md-alt-green; }
figure.alt_light_green { background-color: @md-alt-light-green; }
figure.alt_lime { background-color: @md-alt-lime; }
figure.alt_yellow { background-color: @md-alt-yellow; }
figure.alt_amber { background-color: @md-alt-amber; }
figure.alt_orange { background-color: @md-alt-orange; }
figure.alt_deep_orange { background-color: @md-alt-deep-orange; }
figure.alt_brown { background-color: @md-alt-brown; }
figure.alt_grey { background-color: @md-alt-grey; }
figure.alt_blue_grey { background-color: @md-alt-blue-grey; }